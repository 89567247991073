// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-activities-jsx": () => import("./../../../src/templates/activities.jsx" /* webpackChunkName: "component---src-templates-activities-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-reservation-page-jsx": () => import("./../../../src/templates/reservation-page.jsx" /* webpackChunkName: "component---src-templates-reservation-page-jsx" */),
  "component---src-templates-rooms-jsx": () => import("./../../../src/templates/rooms.jsx" /* webpackChunkName: "component---src-templates-rooms-jsx" */),
  "component---src-templates-simple-markup-jsx": () => import("./../../../src/templates/simple_markup.jsx" /* webpackChunkName: "component---src-templates-simple-markup-jsx" */),
  "component---src-templates-terms-and-conditions-jsx": () => import("./../../../src/templates/terms-and-conditions.jsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-jsx" */)
}

